import React, {useState} from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import get from 'lodash/get'
import Img from 'gatsby-image'
import Layout from '../components/layout'

import heroStyles from '../components/hero.module.css'
import Thumb from "../components/Thumb";

const BlogPostTemplate = (props) => {
  const post = get(props, 'data.contentfulBlogPost')
  const siteTitle = get(props, 'data.site.siteMetadata.title')

  const [currentPic, setCurrentPic] = useState(post.heroImage)

  const click = (pic, e) => {
    pic.width = e.target.clientWidth;
    setCurrentPic(pic)
  }

  let allPics;
  if (post.morePics) {
     allPics = [...post.morePics];
    allPics.unshift(post.heroImage);
  }

  return (
    <Layout location={props.location}>
      <div className="no-aria" >
        <Helmet title={`${post.title} | ${siteTitle}`} />
        <div style={{display: "flex", justifyContent: "center"}}>
          <div className={heroStyles.hero}>
            <Img
                className={heroStyles.heroImage}
                alt={post.title}
                fluid={currentPic.fluid}
            />
            <div className={heroStyles.titleRow}>
              <div className={heroStyles.title}>
                <h1>{post.title}</h1>
                {post.shortDescription}
              </div>
              {post.description &&
                <div className={heroStyles.info}>
                  <div>
                    <div className={heroStyles.title}>
                      <h1>{post.title}</h1>
                      {post.shortDescription}
                    </div>
                    <div
                        dangerouslySetInnerHTML={{
                          __html: post.description.childMarkdownRemark.html,
                        }}
                    />
                  </div>
                  <span>i</span>
                </div>}
            </div>
          </div>
        </div>
      </div>
      {post.morePics &&
      <div className={heroStyles.thumbsWrapper}>
        <div className={heroStyles.thumbs + " thumbs no-aria"}>
          {allPics.map((pic, i) => {
            const isCurrent = currentPic === pic;
           return <Thumb key={i} pic={pic} onClick={(e) => click(pic, e)} isCurrent={isCurrent} />
              }
          )}
        </div>
      </div>}
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    contentfulBlogPost(slug: { eq: $slug }) {
      title
      publishDate(formatString: "YYYY")
      shortDescription
      description {
        childMarkdownRemark {
          html
        }
      }
      heroImage {
        fluid(maxWidth: 1180) {
          ...GatsbyContentfulFluid_noBase64
        }
      }
      morePics {
        fluid(maxWidth: 1180) {
          ...GatsbyContentfulFluid_noBase64
        }
      }
    }
  }
`
