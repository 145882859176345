import React, {useState, useRef} from 'react'
import Img from 'gatsby-image'
import heroStyles from '../components/hero.module.css'

const Item = ({pic, onClick, isCurrent}) => {
    const targetRef = useRef();
    const [width, setWidth] = useState(0);

    const fixWidth = () => {
        if (targetRef.current) {
            setWidth(targetRef.current.scrollWidth);
        }
    }

    return (
        <div
            className={heroStyles.thumb + " " + (isCurrent && heroStyles.hidden)}
            ref={targetRef}
            style={{
                justifyContent: "center",
                opacity: (width === 0) ? "0" : "1",
                width: width,
                display: (width === 0) ? "block" : "flex"
            }}>
            <div className={heroStyles.flyer}>
                <div onClick={onClick}>
                    {pic && <Img onLoad={fixWidth} fluid={pic.fluid}/>}
                </div>
            </div>
        </div>
    )
}

export default Item;